export class Session {
  public static readonly token: string = 'token';

  public static get(key: string) {
    return sessionStorage.getItem(key);
  }

  public static set(key: string, value: string) {
    return sessionStorage.setItem(key, value);
  }

  public static remove(key: string) {
    return sessionStorage.removeItem(key);
  }
}

import axios from 'axios';
import { GenerateOTPRequest } from '../../../models/requests/generateOTP.request';
import { GenerateOTPDto } from '../../../models/dtos/generateOTP.dto';

export default async function generateOTPRequest(
  request: GenerateOTPRequest
): Promise<GenerateOTPDto> {
  const response = await axios.post<GenerateOTPDto>(
    `${process.env.REACT_APP_BASE_URL}/auth/send-otp`,
    request
  );
  return response.data;
}

import { ConfigProvider } from 'antd';
import enUs from 'antd/locale/en_US';
import trTR from 'antd/locale/tr_TR';
import { ReactNode } from 'react';
import useLang from '../core/hooks/useLang';
import { Lang } from '../core/models/enums/lang';
import { UI } from '../core/utilities/ui';
import 'dayjs/locale/tr';

export default function AntdProvider({ children }: { children: ReactNode }) {
  const { lang } = useLang();

  return (
    <ConfigProvider
      locale={lang === Lang.en ? enUs : trTR}
      theme={UI.getTheme()}
    >
      {children}
    </ConfigProvider>
  );
}

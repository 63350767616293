import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorDto } from '../../../models/dtos/error.dto';
import { Errors } from '../../errors';
import { GenerateOTPRequest } from '../../../models/requests/generateOTP.request';
import generateOTPRequest from './generateOTP.repository';
import { GenerateOTPDto } from '../../../models/dtos/generateOTP.dto';

type InitialState = {
  loading: boolean;
  data?: GenerateOTPDto;
  error?: ErrorDto;
};
const initialState: InitialState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const generateOTP = createAsyncThunk(
  'generateOTP',
  async (request: GenerateOTPRequest, { dispatch }) => {
    const response = await generateOTPRequest(request);
    return response;
  }
);

const generateOTPSlice = createSlice({
  name: 'generateOTP',
  initialState,
  reducers: {
    generateOTPReset: (state) => {
      state.loading = false;
      state.data = undefined;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateOTP.pending, (state) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    });
    builder.addCase(
      generateOTP.fulfilled,
      (state, action: PayloadAction<GenerateOTPDto>) => {
        state.loading = false;
        state.data = action.payload;
      }
    );
    builder.addCase(generateOTP.rejected, (state, action) => {
      state.loading = false;
      state.error = Errors.getErrorDtoFromApiError(action.error);
    });
  },
});

export default generateOTPSlice.reducer;
export const { generateOTPReset } = generateOTPSlice.actions;

import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import './App.scss';
import AppLayout from './components/Layouts/AppLayout/AppLayout';
import Loading from './components/Loading/Loading';
import useRouterWithLang from './core/hooks/useRouterWithLang';
import { setAppMounted } from './core/services/app/setAppMounted/setAppMounted.slice';
import { setPathname } from './core/services/app/setPathname/setPathname.slice';
import { setResponseInterceptor } from './core/services/axios';
import { setRemoteConfig } from './core/services/firebase/setRemoteConfig/setRemoteConfig.slice';
import { useAppDispatch, useAppSelector } from './core/services/hooks';
import { AppRouteKey } from './core/utilities/router';
import { Session } from './core/utilities/session';

const LazyLoginPage = React.lazy(
  () => import('./pages/Auth/LoginPage/LoginPage')
);
const LazyDeleteAccountPage = React.lazy(
  () => import('./pages/DeleteAccountPage/DeleteAccountForm')
);
const LazyVerifiedNumberPage = React.lazy(
  () => import('./pages/VerifiedPhoneNumberPage/VerifiedForm')
);
const LazyDeleteSuccesstPage = React.lazy(
  () =>
    import('./pages/SuccessfullyDeletedAccountPage/SuccessfullyDeletedAccount')
);

function App() {
  const isAppMounted = useAppSelector(
    (state) => state.app.setAppMounted.isMounted
  );
  const remoteConfigState = useAppSelector(
    (state) => state.firebase.setRemoteConfig
  );
  const me = useAppSelector((state) => state.app.setMe.me);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { provideUrl } = useRouterWithLang();

  useEffect(() => {
    setResponseInterceptor(dispatch);
    dispatch(setRemoteConfig());
    dispatch(setAppMounted(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPathname(location.pathname));
  }, [dispatch, location]);

  const isAppLoading = (): boolean => {
    return !isAppMounted || remoteConfigState.loading;
  };

  const isAuthenticated = (): boolean => {
    return !!Session.get(Session.token);
  };

  const renderLoading = () => {
    return <Loading className="app-loading" fontSize={64} />;
  };

  const renderLayout = () => {
    return (
      <AppLayout>
        {isAuthenticated() ? (
          <Routes>
            <Route
              path={provideUrl(AppRouteKey.VERIFIED_NUMBER)}
              element={<LazyVerifiedNumberPage />}
            />
            <Route
              path={provideUrl(AppRouteKey.CONFIRM_DELETE)}
              element={<LazyDeleteAccountPage />}
            />
            <Route
              path={provideUrl(AppRouteKey.DELETE_SUCCESS)}
              element={<LazyDeleteSuccesstPage />}
            />
            <Route
              path="*"
              element={
                <Navigate to={provideUrl(AppRouteKey.VERIFIED_NUMBER)} />
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route
              path={provideUrl(AppRouteKey.LOGIN)}
              element={<LazyLoginPage />}
            />
            <Route
              path="*"
              element={<Navigate to={provideUrl(AppRouteKey.LOGIN)} />}
            />
          </Routes>
        )}
      </AppLayout>
    );
  };

  return (
    <div id="app">
      <Suspense fallback={renderLoading()}>
        {isAppLoading() ? renderLoading() : renderLayout()}
      </Suspense>
    </div>
  );
}

export default App;

import axios from 'axios';

import { GenerateOTPDto } from '../../../models/dtos/generateOTP.dto';

export default async function deleteAccountRequest(): Promise<GenerateOTPDto> {
  const response = await axios.delete<GenerateOTPDto>(
    `${process.env.REACT_APP_BASE_URL}/user`
  );
  return response.data;
}
